import * as React from 'react'
// @ts-ignore
import styled from 'styled-components'
import Layout from '../components/Layout/Layout'
import { Color } from '@/theme/colors'
// @ts-ignore
import Image1 from '@/assets/images/spacery-socjalizacyjne-1.png'
import {
  Bolder,
  Header1,
  Header2,
  Header3,
  Paragraph,
} from '@/theme/Typography.styled'
import { Container } from '@/theme/Grid.styled'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Color.Pink_1};
  text-align: center;

  img {
    width: 600px;
    max-width: 90%;
    margin: 30px auto;
  }

  @media (max-width: 991px) {
    svg {
      margin-bottom: 15px;
    }
  }
`

const Description = styled.div`
  padding: 50px 0;

  svg {
    margin: 30px 0;
  }

  @media (max-width: 991px) {
    padding: 0;

    svg {
      margin: 15px 0;
    }
  }
`

const Pricing = styled.div`
  display: grid;
  grid-row-gap: 30px;
  justify-content: center;
  align-content: center;
  padding: 50px 0 70px;

  @media (max-width: 991px) {
    grid-row-gap: 15px;
    padding: 30px 0;
  }
`

const Head = () => (
  <>
    <title>Pet School - Spacery socjalizacyjne</title>
  </>
)

const SocializationWalksPage = () => {
  return (
    <>
      <Head />
      <Layout>
        <Wrapper>
          <Container>
            <img alt="Pet School - spacery socjalizacyjne" src={Image1} />
            <Description>
              <Header2 marginBottom={39}>
                <Bolder>Spacery socjalizacyjne</Bolder>
              </Header2>

              <Paragraph>
                Spacer socjalizacyjny uczy psa przebywania z innymi psami,
                opanowania emocji, wyciszenia, szacunku do przestrzeni innego
                psa. Dla psów jest to okazja do komunikowania się w spokojnych
                warunkach. Lękliwe psy nabierają pewności siebie. Właściciel
                uczy się rozumieć mowę ciała swojego psa. Przewodnik i pies
                zwiększają do siebie zaufanie.
              </Paragraph>

              <Paragraph>
                W trakcie spacerów psy są pod kontrolą opiekunów na
                smyczy/lince. Zadaniem opiekunów jest zachowanie odpowiedniej
                odległości od innych uczestników dla komfortu psów. Nie karmimy
                obcych psów. Psy chore oraz suczki z cieczką nie mogą
                uczestniczyć w spotkaniu. Odpowiedzialność za swojego psa ponosi
                opiekun.
              </Paragraph>
            </Description>
          </Container>

          <Container>
            <Pricing>
              <Paragraph>
                <strong>
                  Spacery odbywają się na wałach na Praczach Odrzańskch.
                </strong>
              </Paragraph>

              <div>
                <Paragraph>Koszt pojedynczego spaceru:</Paragraph>
                <Header1>
                  <Bolder>30 zł</Bolder>
                </Header1>
              </div>

              <Paragraph>
                Częstotliwość:{` `}
                <Bolder>Dwa razy w miesiącu</Bolder>
              </Paragraph>
            </Pricing>
          </Container>
        </Wrapper>
      </Layout>
    </>
  )
}

export default SocializationWalksPage
